import React from "react";
import {
  cPolaroid,
  bandInfo,
  image,
  figure,
  figcaption,
} from "./c-polaroid.module.scss";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Polaroid({ avatar, children }) {
  return (
    <div className={cPolaroid}>
      {avatar && (
        <div className="image is-1by1">
          <figure className={figure}>
            <GatsbyImage
              image={getImage(avatar)}
              className={image}
              sizes="(max-width: 768px) 100w, 265px"
            />
            {avatar.description && (
              <figcaption className={figcaption}>
                {avatar.description}
              </figcaption>
            )}
          </figure>
        </div>
      )}

      <div className={bandInfo}>{children}</div>
    </div>
  );
}

Polaroid.propTypes = {
  avatar: PropTypes.object,
  children: PropTypes.object,
};
