import { graphql } from "gatsby";
import React, { Children } from "react";
import "../../index.scss";
import {
  Section,
  Container,
  Columns,
  Heading,
  Block,
} from "react-bulma-components";

import Layout from "../../components/layouts/main-layout/index.jsx";

import PersonsSinglePropTypes from "./types";
import CardTitle from "../../components/elements/card-title";
import CardSubtitle from "../../components/elements/card-subtitle";
import Event from "../../components/bits/event";

import { header } from "./t-person.module.scss";
import Polaroid from "../../components/elements/polaroid";
import filteredEvents from "../../utils/helpers/filtered-events";
import { flattenDeep } from "lodash";
import CardAside from "../../components/card-aside";

export default function PersonsSingle({ data }) {
  if (!data) {
    return null;
  }

  const { name, musician, images, socialMedia } = data.contentfulPerson;

  const instruments = [
    ...new Set(
      musician
        .map(({ instrument }) => instrument?.map(({ name }) => name))
        .flat()
    ),
  ].join(", ");

  const lineUpWithAvatar = musician.filter(({ avatar }) => !!avatar);

  const lineUpFirstAvatar =
    lineUpWithAvatar?.length > 0 ? lineUpWithAvatar[0].avatar : null;

  const imageFromLineUp = lineUpFirstAvatar || null;

  const cover = images ? images[0] : imageFromLineUp;
  const events = flattenDeep(
    musician
      .filter(({ band }) => !!band)
      .map(({ band }) => (band[0].event?.length > 0 ? band[0].event : null))
      .filter((e) => !!e)
  );

  const activeEvents = filteredEvents(events);

  return (
    <Layout
      title={name}
      description={instruments}
      heroImage={cover}
      socialMedia={socialMedia}
      logoNavigation={data.logoNavigation}
      jsonLdData={[
        {
          "@context": "https://schema.org",
          "@type": "Person",
          name: { name },
          jobTitle: "Musician",
        },
      ]}
    >
      <div>
        <Section>
          <Container>
            <Block>
              <Columns>
                <Columns.Column tablet={{ size: 3 }} desktop={{ size: 3 }}>
                  <CardAside title="Formacions"></CardAside>
                </Columns.Column>
                <Columns.Column tablet={{ size: 9 }} desktop={{ size: 9 }}>
                  <Columns gap={4}>
                    {musician
                      ?.sort((a, b) => {
                        const yearA = a.band[0]?.edition[0]?.year || 0;
                        const yearB = b.band[0]?.edition[0]?.year || 0;

                        return yearB - yearA;
                      })
                      .map(({ avatar, band }) => {
                        return band.map(({ slug, title, edition }) => (
                          <Columns.Column tablet={{ size: 6 }} key={slug}>
                            <Polaroid avatar={avatar}>
                              <CardTitle
                                negative
                                href={`/grups/${slug}`}
                                title={title}
                              />
                              {edition && edition.length > 0 && (
                                <CardSubtitle
                                  href={`/edicions/${edition[0]?.year}`}
                                  subtitle={edition[0]?.year}
                                  dark
                                />
                              )}
                            </Polaroid>
                          </Columns.Column>
                        ));
                      })}
                  </Columns>
                </Columns.Column>
              </Columns>
              {activeEvents?.length > 0 && (
                <Columns>
                  <Columns.Column tablet={{ size: 3 }} desktop={{ size: 3 }}>
                    <CardAside title="Agenda"></CardAside>
                  </Columns.Column>
                  <Columns.Column tablet={{ size: 9 }} desktop={{ size: 9 }}>
                    {activeEvents.map((eventData) => (
                      <Event transparent {...eventData} key={eventData.id} />
                    ))}
                  </Columns.Column>
                </Columns>
              )}
            </Block>
          </Container>
        </Section>
      </div>
    </Layout>
  );
}

PersonsSingle.propTypes = PersonsSinglePropTypes;

export const query = graphql`
  query PersonBySlug($slug: String) {
    logoNavigation: file(extension: {}, name: { eq: "logo-navigation" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      slug
      name
      images {
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      musician {
        instrument {
          name
        }
        band {
          slug
          id
          title
          edition {
            year
          }
          event {
            ...EventData
          }
        }
        avatar {
          description
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      socialMedia {
        type
        url
      }
    }
  }
`;
